import React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

import './InputCheckbox.scss'

import eye from "../../img/eye.svg"
import eyeoff from "../../img/eye-closed.svg"


function InputCheckbox(props) {
    const { 
        size="md",
        ref=undefined,
        label="",
        checked=false,
        onChange=()=>{}
    } = props;

    const [inputRef, setInputRef] = useState(useRef());
    // const [checked, setChecked] = useState(false);

    useEffect(() => {
        if ( ref !== undefined ) {
            setInputRef(ref);
        }
    }, [])

    return (
        <div className={"console-input-checkbox " + size}>
            <input 
                type="checkbox" 
                id={label} 
                name={label} 
                checked={checked} 
                onChange={onChange} 
            />
            <label htmlFor={label}>{label}</label>
        </div>
    )
}

export default InputCheckbox;