import React from 'react';
import styled from 'styled-components';
import BaseWalkthrough, { basic_option } from './BaseWalkthrough';


const StepContent = styled.div`
	font-size: 20px;
`;

function WalkthroughYourServices(props) {
	const { callback=()=>{} } = props;
	
	const steps = [
		{
			content: (
				<StepContent>
					In this page, you can see all services that you have paid
				</StepContent>
			),
			...basic_option,
			placement: 'center',
			target: 'body',
		}, {
			content: (
				<StepContent>
					Click one of the service card to see your service status, like quota, usage, etc
				</StepContent>
			),
			...basic_option,
			target: ".main-card-container .row"
		},{
			content: (
				<StepContent>
					Try to click here
				</StepContent>
			),
			...basic_option,
			target: ".dashboard-card-col"
		}
	];

    const _callback = (data) => {
		callback(data);
    }

	// console.log(steps)

	return (
        <>
			<BaseWalkthrough steps={steps} callback={_callback} />
        </>
    )
}

export default WalkthroughYourServices;