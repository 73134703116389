import React from 'react';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router'; 
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getServiceNameByAcronym } from '../../utils/services';
import BaseWalkthrough, { basic_option } from './BaseWalkthrough';


const StepContent = styled.div`
	font-size: 20px;
`;

function WalkthroughYourServiceDetail(props) {
	const { callback=()=>{} } = props;

	const userDetail = useSelector(state => state.UserManagementReducers.userDetail)

	const [username, setUsername] = useState("")
	
	const service_acr 	= props.service_acr.replace(/[^a-z]/ig, ''); 
	const service_name	= getServiceNameByAcronym(service_acr);
	
	const steps = [
		{
			content: (
				<StepContent>
					In this page, you can find information about {service_name} service that you have paid for					
				</StepContent>
			),
			...basic_option,
			placement: 'center',
			target: 'body',
		},{
			content: (
				<StepContent>
					Use this token for authorization when using our {service_name}. You can also get it via request get token api.
				</StepContent>
			),
			...basic_option,
			target: "#api-key"
		},{
			content: (
				<StepContent>
					You can find your quota balance for {service_name} service 
				</StepContent>
			),
			...basic_option,
			target: ".status-card#quota .status-card-container"
		},{
			content: (
				<StepContent>
					This your {service_name} service usage history, it contains information like usage date and total usage everytime you use the service
				</StepContent>
			),
			...basic_option,
			target: "#usage-history"
		},{
			content: (
				<StepContent>
					If you want to buy more quota, you can click this button and you will be redirected to the {service_name} service information page, you can choose the package you like there
				</StepContent>
			),
			...basic_option,
			target: "#dashboardDetailServices_addQuota"
		},{
			content: (
				<StepContent>
					It's time to try {service_name} application, click this button to go to the application page
				</StepContent>
			),
			...basic_option,
			target: "#dashboardDetailServices_goToApp"
		},{
			content: (
				<StepContent>
					Click here to learn more about how to use our {service_name}
				</StepContent>
			),
			...basic_option,
			target: "#dashboardDetailServices_seeDoc"
		},
	];
	
	useEffect(() => {
		if ( userDetail === null )
			return;

		// get firstname from userDetail data
		const firstname = userDetail.firstname !== "" ? userDetail.firstname : userDetail.id_user;
		setUsername(firstname);
	}, [userDetail])

    const _callback = (e) => {
		callback(e);
	}

	// console.log(steps)

	return (
        <>
			<BaseWalkthrough steps={steps} callback={_callback} />
        </>
    )
}

export default WalkthroughYourServiceDetail;