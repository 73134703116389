import { toastNotification } from "../modules/ToastNotification"
import { history } from "../store"
import { fetchRefreshToken } from "../reducers/UserManagementReducers"

// Actions
const REQ_USERPACKAGE_LIST = "UserPackageReducers/REQ_USERPACKAGE_LIST";
const REQ_USERPACKAGE_DETAIL = "UserPackageReducers/REQ_USERPACKAGE_DETAIL";
const REQ_USAGE_HISTORY = "UserPackageReducers/REQ_USAGE_HISTORY";
const REQ_PACKAGE_UNIT = "UserPackageReducers/REQ_PACKAGE_UNIT";

// Action Creators
/* 
 *  !LEGEND! 
 *      req = request // set
 *      rcv = receive // get
 *      fch = fetch
 *      snd = send
 */

export const reqUserPackageList = data => ({
    type: REQ_USERPACKAGE_LIST,
    payload: {
        userpackage_list: data,
        status: "data_UserPackage_list"
    }
});

export const reqUserPackageDetail = data => ({
    type: REQ_USERPACKAGE_DETAIL,
    payload: {
        userpackage_detail: data,
        status: "data_UserPackage_detail"
    }
});

export const reqUsageHistory = (history) => ({
    type: REQ_USAGE_HISTORY,
    payload: {
        data_usage_history: history,
        status: "data_usage_history"
    }
});

export const reqPackageUnit = (unit) => ({
    type: REQ_PACKAGE_UNIT,
    payload: {
        data_package_unit: unit,
        status: "data_package_unit"
    }
});

export const fetchUserPackageList = (id_token) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const body = {
            id_token: id_token,
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/userpackage/list/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    let Package = (result.packages) ? result.packages : []
                    dispatch(reqUserPackageList(Package));
                    resolve(Package)
                }
                else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchUserPackageList(id_token))
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }

            })
            .catch(err => {
                dispatch(reqUserPackageList([]));
                toastNotification(
                    'error',
                    'Gagal memuat data paket pengguna',
                    err.toString()
                );
                reject(err)
            });
    });
};


export const fetchUserPackageDetail = (id_token, id_user_package) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const body = {
            id_token: id_token,
            id_user_package: id_user_package
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/userpackage/detail/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    // dispatch(reqUserPackageDetail(result));
                    resolve(result);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchUserPackageDetail(id_token, id_user_package))
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                toastNotification(
                    'error',
                    'Gagal memuat detail paket pengguna',
                    err.toString()
                );
                // dispatch(reqUserPackageDetail(null));

                reject(err)
            });
    });
};


export const fetchUsageHistory = (id_token, id_user_package, offset, limit) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const body = {
            id_token: id_token,
            id_user_package: id_user_package,
            offset: offset,
            limit: limit,
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/userpackage/usagehistory/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    // dispatch(reqUsageHistory(result.history));
                    // dispatch(reqPackageUnit(result.unit));

                    resolve(result);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchUsageHistory(id_token, id_user_package, offset, limit))
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                toastNotification(
                    'error',
                    'Gagal memuat riwayat penggunaan',
                    err.toString()
                );
                // dispatch(reqUsageHistory([]));

                reject(err)
            });
    });
};

export const fetchGenerateSTTApiKey = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const body = {
            token: getState().UserManagementReducers.id_token
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/service/stt/generateapikey/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    const api_key = result.api_key;
                    // console.log(api_key)
                    resolve(api_key);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchGenerateSTTApiKey())
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                toastNotification(
                    'error',
                    'Failed generate stt api key',
                    err.toString()
                );
                // dispatch(reqUserPackageDetail(null));

                reject(err)
            });
    });
};

export const fetchGenerateTTSApiKey = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const body = {
            token: getState().UserManagementReducers.id_token
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/service/tts/generateapikey/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    const api_key = result.api_key;
                    // console.log(api_key)
                    resolve(api_key);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchGenerateTTSApiKey())
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                toastNotification(
                    'error',
                    'Failed generate tts api key',
                    err.toString()
                );
                // dispatch(reqUserPackageDetail(null));

                reject(err)
            });
    });
};


export const fetchGenerateTextTranslateApiKey = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const body = {
            token: getState().UserManagementReducers.id_token
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/service/text/translate/generateapikey/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    const api_key = result.api_key;
                    // console.log(api_key)
                    resolve(api_key);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchGenerateTextTranslateApiKey())
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                toastNotification(
                    'error',
                    'Failed generate tts api key',
                    err.toString()
                );
                // dispatch(reqUserPackageDetail(null));

                reject(err)
            });
    });
};

export const fetchGenerateAudioTranslateApiKey = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const body = {
            token: getState().UserManagementReducers.id_token
        };

        fetch(process.env.REACT_APP_SERVER_URL + "/app/console/" + process.env.REACT_APP_SYSTEM_STATUS + "/service/audio/translate/generateapikey/", {
            headers: {
                "Content-Type": 'application/json'
            },
            method: "POST",
            body: JSON.stringify(body)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw (response.status + " " + response.statusText)
                }
            })
            .then(result => {
                if (result.status === "success") {
                    const api_key = result.api_key;
                    // console.log(api_key)
                    resolve(api_key);

                } else {
                    if (result.error === "token expired") {
                        fetchRefreshToken()
                            .then((id_token) => {
                                dispatch(fetchGenerateAudioTranslateApiKey())
                                    .then(() => { })
                                    .catch(() => { });
                            })
                            .catch((err) => {
                                history.push("/login");
                            });
                    }
                    else {
                        throw (result.error)
                    }
                }
            })
            .catch(err => {
                toastNotification(
                    'error',
                    'Failed generate tts api key',
                    err.toString()
                );
                // dispatch(reqUserPackageDetail(null));

                reject(err)
            });
    });
};


// Reducer's initial state
const initialState = {
    userpackage_list: null,
    userpackage_detail: null,
    data_usage_history: [],
    data_package_unit: "",
    status: '',
}

// Reducers
export default function UserPackageReducers(state = initialState, action) {
    switch (action.type) {
        case REQ_USERPACKAGE_LIST:
            return {
                ...state,
                status: action.payload.status,
                userpackage_list: action.payload.userpackage_list
            };
        case REQ_USERPACKAGE_DETAIL:
            return {
                ...state,
                status: action.payload.status,
                userpackage_detail: action.payload.userpackage_detail
            };
        case REQ_USAGE_HISTORY:
            return {
                ...state,
                status: action.payload.status,
                data_usage_history: action.payload.data_usage_history
            };
        case REQ_PACKAGE_UNIT:
            return {
                ...state,
                status: action.payload.status,
                data_package_unit: action.payload.data_package_unit
            };
        default:
            return state;
    }
}