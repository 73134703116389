import React, { Component } from 'react';
import { withRouter } from 'react-router'; 
import { connect } from "react-redux";
import store, { history } from '../../../../store';

// Functions
import { fetchServiceList } from "../../../../reducers/ServiceReducers"
import { getServiceNameByAcronym, getServicePathByAcronym } from '../../../../utils/services';

// Components
import { Row, Col, Badge } from 'react-bootstrap';
import Loading from '../../../../components/Loading';
import ServicesCard from '../../../../components/Cards/ServicesCards';

// Styles
import "../Board.scss"
import "./Services.scss"

// Assets
// import close from "../../../../img/navigation_close.svg"


class Services extends Component {
    componentDidMount(){
        store.dispatch(fetchServiceList(this.props.id_token))
            .then(()=>{})
            .catch(()=>{})
    }

    componentWillUnmount(){
        // store.dispatch(reqServiceList([]))
    }

    render() {
        // get firstname from userDetail data
        let firstname = "";
        if ( this.props.userDetail !== null ) {
            firstname = this.props.userDetail.firstname;

            if ( firstname === "" ) {
                firstname = this.props.userDetail.id_user;
            }
        }
    
        const service_list = this.props.service_list;

        let platforms   = [];
        let developers  = [];

        if ( Array.isArray(service_list) ) {
            platforms = service_list.filter(({name}) => !name.toLowerCase().includes("stt") && !name.toLowerCase().includes("tts")&& !name.toLowerCase().includes("text-translate")&& !name.toLowerCase().includes("audio-translate"));
            platforms = platforms.map(({name, ...restprops}) => ({...restprops, name: getServiceNameByAcronym(name), path: getServicePathByAcronym(name)}));

            developers = service_list.filter(({name}) => name.toLowerCase().includes("stt") || name.toLowerCase().includes("tts")||name.toLowerCase().includes("text-translate")||name.toLowerCase().includes("audio-translate")||name.toLowerCase().includes("credits"));
            developers = developers.map(({name, ...restprops}) => ({...restprops, name: getServiceNameByAcronym(name), path: getServicePathByAcronym(name)}));
        }
        
        return (
            <>
                <div id="services-container">
                    {/* <div className="main-bg"></div> */}
                    {/* <div className="btn-close" onClick={()=>history.push("/")}>
                        <img src={close} alt="navigation-close" id="dashboard_addservices_close"/>
                    </div> */}
                    <div className="main-container">
                        <div className="main-header">
                            <div className="main-bg"></div>
                            <h3><span role="img" aria-label="icon">👋</span>  Selamat Datang, {firstname}!</h3>
                            <p>Pilih Paket Layanan sesuai dengan kebutuhanmu</p>
                            {/* <h3>Pilih Layanan</h3>
                            <p>Pilih Paket Layanan sesuai dengan kebutuhanmu</p> */}
                        </div>
                        <div className="main-card-container">
                            <Row>
                                <Col className="dashboard-card-col" xs={12} xl={7}>
                                    <div className="services-col">
                                        <div className="title-col">Our Services</div>
                                        <div className="content-col">
                                            { service_list === null ?     
                                                <Loading /> 
                                                :
                                                <>
                                                    <div className="services-card-container">
                                                            <h3 className="title">Platform</h3>
                                                            <div className="content">
                                                                <Row>
                                                                    { platforms.map((service, i) => 
                                                                        <div key={service.name} className="service-card-col">
                                                                            <ServicesCard
                                                                                key={i}
                                                                                id={service.path.replace('\/','')}
                                                                                planType=""
                                                                                title={service.name}
                                                                                desc={service.description}
                                                                                // onAddNew={() =>{history.push(`/package/${service.id_service}`)}}
                                                                                onAddNew={() =>{history.push(service.path)}}
                                                                            />
                                                                        </div>
                                                                    ) } 
                                                                </Row>  
                                                            </div>
                                                    </div>         
                                                    <div className="services-card-container">
                                                        <h3 className="title">Developer <Badge variant="light">API</Badge></h3>
                                                        <div className="content">
                                                            <Row>
                                                                { developers.map((service, i) => 
                                                                    <div key={service.name} className="service-card-col">
                                                                        <ServicesCard
                                                                            key={i}
                                                                            id={service.path.replace('\/','')}
                                                                            planType=""
                                                                            title={service.name}
                                                                            desc={service.description}
                                                                            // onAddNew={() =>{history.push(`/package/${service.id_service}`)}}
                                                                            onAddNew={() =>{history.push(service.path)}}
                                                                        />
                                                                    </div>
                                                                ) } 
                                                            </Row>  
                                                        </div>
                                                    </div>
                                                </>
                                            }   
                                        </div> 
                                    </div>
                                </Col>
                
                                <Col className="dashboard-card-col" xs={12} xl={5}>
                                    <div className="services-card-container">
                                        <h3 className="title">Documentation</h3>
                                        <div className="content">
                                            <div className="docs-link">
                                                <a href={window._link_.stt_api_docs} target="_blank" rel="noopener noreferrer">
                                                    <Badge variant="dark">API</Badge>
                                                    <span>Speech to Text</span>
                                                </a>
                                            </div>
                                            <div className="docs-link">
                                                <a href={window._link_.tts_api_docs} target="_blank" rel="noopener noreferrer">
                                                    <Badge variant="dark">API</Badge>
                                                    <span>Text to Speech</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="services-card-container">
                                        <h3 className="title">Links</h3>
                                        <div className="content">
                                            <div className="docs-link">
                                                <a href={window._link_.contact} target="_blank" rel="noopener noreferrer">
                                                    <span>Contact Us</span>
                                                </a>
                                            </div>
                                            <div className="docs-link">
                                                <a href="/terms-service" target="_blank" rel="noopener noreferrer">
                                                    <span>Terms and Conditions</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        id_token: state.UserManagementReducers.id_token,
        userDetail : state.UserManagementReducers.userDetail,
        service_list: state.ServiceReducers.service_list,
    };
}

export default withRouter(connect(mapStateToProps)(Services));