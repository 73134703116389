import React, { Component, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import store, { history } from "../../store"

// FUNCTIONS
import { fetchServiceList, fetchServicePackage, updateServicePackage, reqServicePackage } from "../../reducers/ServiceReducers"
import { updateContinueToCheckoutFlag } from '../../reducers/BillingReducers';

// COMPONENTS
import { Row, Col, Button, ListGroup } from 'react-bootstrap'
import Loading from '../Loading';
import BillingStepHeader from '../BillingStepHeader/BillingStepHeader';

// STYLES
import "./BilingPackage.scss"

// Asset
import bg from '../../img/landing/card-bg.svg'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function BilingPackage(props) {
    const { landing } = props;

    const dispatch = useDispatch();

    const { id_token } = useSelector(state => state.UserManagementReducers);
    const { service_package, service_list } = useSelector(state => state.ServiceReducers)

    const [idService, setIdService] = useState(null);
    const [serviceName, setServiceName] = useState("");

    useEffect(() => {
        if (landing) {

            // // if (window.location.pathname === "/tts") {
            // //     id_service = "2";
            // //     this.setState({ service_name: "Text to Speech" })
            // // }
            // // else if (window.location.pathname === "/stt") {
            // //     id_service = "3";
            // //     this.setState({ service_name: "Speech To Text" })
            // // }
            // // else if (window.location.pathname === "/notulite") {
            // //     id_service = "4";
            // //     this.setState({ service_name: "Notulite" })
            // // }
            // // else if (window.location.pathname === "/notula") {
            // //     id_service = "4";
            // //     this.setState({ service_name: "Notula" })
            // // }
            // // else if (window.location.pathname === "/diktein") {
            // //     id_service = "4";
            // //     this.setState({ service_name: "Diktein" })
            // // }

            let service_name = history.location.pathname.replace('/', '');
            if (service_name.indexOf("notula") > -1) {
                service_name = "notula";
            } else if (service_name.indexOf("notulite") > -1) {
                service_name = "notulite";
            } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1 || service_name == "") {
                service_name = "dikte.in";
            }
            // } else if ( service_name.indexOf("stt") > -1 ) {
            //     service_name = "stt";
            // } else if ( service_name.indexOf("tts") > -1 ) {
            //     service_name = "tts";
            // }

            setServiceName(service_name);

        } else {
            let id_service = parseInt(props.match.params.id_service);
            setIdService(id_service)
        }


        return () => {
            dispatch(reqServicePackage([]));
        }

    }, [])

    useEffect(() => {

        if (!Array.isArray(service_list))
            return;

        // console.log(serviceName)
        // console.log(service_list)

        if (landing) {
            const index = service_list.findIndex(s => s.name.toLowerCase() === serviceName.toLowerCase());
            if (index > -1) {
                let id_service = service_list[index].id_service;
                setIdService(id_service)
            }

        } else {
            const index = service_list.findIndex(s => s.id_service === idService);
            if (index > -1) {
                let service_name = service_list[index].name;
                // if ( service_name.toLowerCase() === "stt" ) {
                //     service_name = "Speech to Text";
                // } else if ( service_name.toLowerCase() === "tts" ) {
                //     service_name = "Text to Speech";
                // } else {
                //     service_name = service_name.charAt(0).toUpperCase() + service_name.slice(1);;
                // } 

                if (service_name.indexOf("notula") > -1) {
                    service_name = "notula";
                } else if (service_name.indexOf("notulite") > -1) {
                    service_name = "notulite";
                } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1) {
                    service_name = "dikte.in";
                } else if (service_name.indexOf("stt") > -1) {
                    service_name = "stt";
                } else if (service_name.indexOf("tts") > -1) {
                    service_name = "tts";
                }

                setServiceName(service_name)
            }
        }

    }, [service_list])

    useEffect(() => {
        store.dispatch(fetchServiceList(id_token))
            .then(() => { })
            .catch(() => { });
    }, [idService, serviceName])

    useEffect(() => {
        if (idService !== null) {
            // console.log("idService: "+idService)

            // dispatch(reqServicePackage([]))
            store.dispatch(fetchServicePackage(idService))
                .then(() => { })
                .catch(() => { });
        }
    }, [idService])

    return (
        <div
            id="biling-menu-container"
            className="pt-3 pr-4 pl-4 rounded-lg"
            style={{ backgroundColor: "#E7F0FF" }}
        >
            <h2
                className="text-center mb-5 font-weight-bold display-4"
                style={{ color: "#4087EE" }}
            >
                Paket Harga
            </h2>

            {/* Render Billing Header if not landing */}
            {!landing && <BillingStepHeader currentStep={1} totalStep={5} />}

            <Row>
                {service_package.length === 0 ? (
                    <Loading />  // Render Loading component if no packages
                ) : (
                    service_package.map((packages, i) => {
                        if (packages.name !== "Free") {
                            return (
                                <Col
                                    key={i}
                                    lg={3}
                                    md={6}
                                    sm={12}
                                    className="d-flex justify-content-center mb-4"
                                >
                                    <CardBillingPackage
                                        key={i}
                                        id_token={id_token}
                                        id_package={packages.id_package}
                                        title={packages.name}
                                        services={(packages.description) ? packages.description : []}
                                        price={parseInt(packages.price)}
                                        discount={parseInt(packages.discount)}
                                        minimal={parseInt(packages.minimal)}
                                        unit={packages.unit_name !== null && packages.unit_name !== undefined ? packages.unit_name : "Unit"}
                                        selected_packages={packages}
                                        single={service_package.length > 1 || !landing ? false : true}
                                    />
                                </Col>
                            );
                        }
                        return null;
                    })
                )}
            </Row>
        </div>
    )
}

class CardBillingPackage extends Component {
    redirectToUrl = async () => {
        await store.dispatch(updateContinueToCheckoutFlag(true));
        await store.dispatch(updateServicePackage(this.props.selected_packages));
        console.log(`this.props.selected_packages`, this.props.selected_packages);


        if (this.props.id_token === null || this.props.id_token === '') {
            history.push("/login");
        } else {
            history.push("/cart");
        }
    }

    priceFormat = (value) => {
        let price = value;
        // Trilyun
        if (price / 1000000000000 >= 1) {
            price = parseFloat((price / 1000000000000).toFixed(2)).toString() + " Trilyun";
        }
        // Milyar
        else if (price / 1000000000 >= 1) {
            price = parseFloat((price / 1000000000).toFixed(2)).toString() + " Milyar";
        }
        // Juta
        else if (price / 1000000 >= 1) {
            price = parseFloat((price / 1000000).toFixed(2)).toString() + " Juta";
        }
        // Ribu
        else if (price / 1000 >= 1) {
            price = parseFloat((price / 1000).toFixed(2)).toString() + " Ribu";
        }
        return price;
    }

    priceFormat2 = (value) => {
        let price = Math.floor(value / 100) * 100
        return new Intl.NumberFormat("id-UD", {
            style: "currency",
            currency: "IDR"
        }).format(price);

    }

    render() {
        let { title, price, unit, services, discount, minimal } = this.props;

        // console.log(services, `services`);
        // console.log(minimal, `minimal`);
        // console.log(discount, `discount`);
        // console.log(unit, `unit`);





        // const parsedServices = services.flatMap(service =>
        //     service.split(/[;]+/).map(item => item.trim())
        // );
        // Ambil elemen pertama dari array services dan split-nya
        const parsedServices = services && services.length > 0
            ? services[0].split(/[;]+/).map(item => item.trim())
            : []; // Jika services kosong atau undefined, kembalikan array kosong

        // Ambil elemen kedua dari array services jika ada
        const secondService = services && services.length > 1
            ? services[1].trim()
            : null; // Jika array kedua tidak ada, atur sebagai null

        // console.log(`secondService`, secondService);

        const test = services[1]
        // console.log(`test`, test);

        if (Array.isArray(services) && services[1]) {
            const secondService = services[1];
            console.log("Second service found:", secondService);
        } else {
            console.warn("Second service tidak ditemukan atau array tidak valid.");
        }



        return (
            <div className={`card shadow-lg border-0 rounded-lg p-4 h-100 w-100 ${services.length < 5 ? "" : "extend"}`}>
                {title === 'Custom' ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex flex-column align-items-center text-primary">
                            <h2 className="font-weight-bold mb-2">{title}</h2>



                            <div className="text-muted mb-4">Paket Terbaik untuk Bisnis Anda</div>
                            <a href={`${window._link_.contact}`} target="_blank" rel="noopener noreferrer" className="btn btn-primary text-white font-weight-semibold px-4 py-2 rounded-pill">
                                Hubungi Kami
                            </a>
                        </div>
                    </div>
                ) : (
                    // Your existing code for non-custom titles
                    <>
                        {/* Discount Label */}
                        {unit.toLocaleLowerCase().includes("custom") ? null : discount !== 0 && (
                            <div className="w-100 text-white py-2 text-center rounded mb-3" style={{ backgroundColor: "#4087EE" }}>
                                Harga diskon!
                            </div>
                        )}

                        <div className=''>
                            {/* Price Section */}
                            {unit.toLocaleLowerCase().includes("custom") ? null : discount !== 0 ? (
                                <>
                                    <h5 className="font-weight-bold mb-2">{title}</h5>
                                    <p style={{ color: 'orange', fontSize: '15px' }}>
                                        {secondService}
                                    </p>
                                    <div className="price mb-2">
                                        <span className="h3 font-weight-bold">{this.priceFormat2(discount)}</span>
                                        <span className="text-secondary medium"> / {unit.replace("_callus", "")}</span>
                                    </div>
                                    <div className="text-muted mb-3">
                                        <span className="price-stroke">{this.priceFormat2(price)}</span>
                                        <span> (Harga Normal)</span>
                                    </div>
                                </>
                            ) : (
                                <div className="price mb-2">
                                    <span className="h3 font-weight-bold">{this.priceFormat2(price * minimal)}</span>
                                    <span className="text-secondary medium"> / {unit.replace("_callus", "")}</span>
                                </div>
                            )}

                            {/* Services List */}
                            <ListGroup className="mb-4">
                                <div className="font-weight-bold mb-2" style={{ color: "#3372CA", fontSize: "18px" }}>
                                    Senilai {parsedServices[0]}:
                                </div>

                                {parsedServices.slice(1).map((value, i) => (
                                    <ListGroup.Item key={i} className="border-0 d-flex align-items-start bg-transparent px-0">
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCheck} className="mr-2 text-primary" />
                                            <span className="text-sm">{i === 0 ? value : `Atau ${value}`}</span>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>

                        {/* Buy Button */}
                        <Button onClick={this.redirectToUrl} id="buy-btn" className="btn-block rounded-pill font-weight-bold py-2" style={{ background: 'linear-gradient(to right, #76AFFF, #3D81E1)', border: 'none' }}>
                            Beli Sekarang
                        </Button>
                    </>
                )}
            </div>


        )
    }
}

export default BilingPackage;

