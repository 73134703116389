import React from 'react';
import { Button, Card, Col, Container, Row, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const dummyData = [
    {
        name: "MINI",
        price: 50000,
        unit_name: "Bulan",
        credit_info: "Includes 10 million credits:",
        description: [
            "30 hours speech-to-text",
            "or 10 million words code-mixing",
            "or 20 million words summarize"
        ]
    },
    {
        name: "MIDI",
        price: 100000,
        unit_name: "Bulan",
        credit_info: "Includes 20 million credits:",
        description: [
            "60 hours speech-to-text",
            "or 20 million words code-mixing",
            "or 30 million words summarize"
        ]
    },
    {
        name: "MAXI",
        price: 150000,
        unit_name: "Bulan",
        credit_info: "Includes 30 million credits:",
        description: [
            "90 hours speech-to-text",
            "or 30 million words code-mixing",
            "or 40 million words summarize"
        ]
    }
];

const PricingCards = () => {
    return (
        <Container fluid className="pt-5 bg-white" id="harga">
            <h2 className="text-center text-primary mb-5 fw-bold fs-1">
                Paket Harga
            </h2>
            <Row className="justify-content-center">
                {dummyData.map((data, index) => (
                    <Col key={index} xs={12} md={6} lg={4} className="mb-4">
                        <Card className="shadow border-0 h-100">
                            <Card.Body className="d-flex flex-column justify-content-between p-4">
                                {/* Nama Paket */}
                                <div>
                                    <Card.Title className="text-dark fw-bold fs-4 mb-2">
                                        {data.name}
                                    </Card.Title>
                                    <h3 className="text-dark fw-bold fs-2 mb-3">
                                        Rp{data.price.toLocaleString()}
                                        <span className="fs-6 fw-normal"> / {data.unit_name}</span>
                                    </h3>
                                </div>

                                {/* Informasi Credit */}
                                <p className="text-muted mb-3">{data.credit_info}</p>

                                {/* List Deskripsi */}
                                <ListGroup variant="flush" className="mb-2">
                                    {data.description.map((desc, idx) => (
                                        <ListGroup.Item
                                            key={idx}
                                            className="border-0 d-flex align-items-start bg-transparent px-0"
                                        >
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                className="mr-2 text-primary"
                                            />
                                            {desc}
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>

                                {/* Tombol Beli */}
                                <Button
                                    variant="primary"
                                    className="w-100 rounded-pill py-2 fw-semibold fs-5"
                                    style={{
                                        background: 'linear-gradient(to right, #76AFFF, #3D81E1)',
                                        border: 'none'
                                    }}
                                >
                                    Beli Sekarang
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default PricingCards;
