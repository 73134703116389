import { useEffect } from "react";
import React from 'react';
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toastNotification } from "../../modules/ToastNotification";
import ModalConfirmPassword from "../../page/Dashboard/Board/Account/ModalConfirmPassword";
import { fetchUserChangeEmail, fetchUserUpdate } from "../../reducers/UserManagementReducers";
import Input from "../Input/Input";

import './Account.scss'
import ModalVerifyEmailSent from "./ModalVerifyEmailSent";


function ModalChangeEmail(props) {
    const { 
        show=false, 
        onHide=()=>{}, 
        onCancel=()=>{},
    } = props;

    const dispatch = useDispatch();

	const { userDetail } = useSelector(state => state.UserManagementReducers);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");

    const [showModalConfirmPassword, setShowModalConfirmPassword] = useState(false);
    const [showModalVerifyEmailSent, setShowModalVerifyEmailSent] = useState(false);

    useEffect(() => {
        if ( show ) {
            setEmail(userDetail.email)
        }
    }, [show])

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setShowModalConfirmPassword(true)
    }

    const onConfirmPassword = (password) => {
        setLoading(true);
        
        const data = {
            old_email: userDetail.email, 
            new_email: email, 
            password: password, 
        };

        dispatch(fetchUserChangeEmail(data))
            .then((res) => {
                setLoading(false);

                onHide();
                setShowModalConfirmPassword(false)
                setShowModalVerifyEmailSent(true);
            })
            .catch((err) => {
                setLoading(false);
        
                setShowModalConfirmPassword(false)

                toastNotification("error", "Update email failed", err.toString());
            })
    }

    return (
        <>
            <ModalConfirmPassword
                show={showModalConfirmPassword} 
                // show={true} 
                onHide={() => setShowModalConfirmPassword(false)} 
                onSubmit={onConfirmPassword}
                loading={loading}
            />
            
            <ModalVerifyEmailSent 
                show={showModalVerifyEmailSent}
                onHide={() => setShowModalVerifyEmailSent(false)}
                email={email}
            />

            <Modal 
                className="modal-change-email" 
                show={show} 
                onHide={onHide}
                backdrop="static"
                centered
            >
                {/* <Modal.Header>
                    <div className="title">Enter your email to proceed</div>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="title">Change Email</div>
                    <div className="content">
                            <form onSubmit={handleFormSubmit}>
                                <Input 
                                    size="md"
                                    alt="input-email" 
                                    type="email" 
                                    placeholder="Email"
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                                <div className="button-group">
                                    <button type="submit" 
                                        className="btn btn-primary" 
                                        id="submit"
                                        disabled={loading || email === userDetail.email || email.length === 0 ? true : false}
                                    >
                                        { loading ?      
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            : "Save"
                                        }
                                    </button>
                                    <button type="button"
                                        className="btn btn-secondary" 
                                        // onClick={onHide}
                                        onClick={onCancel}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalChangeEmail;