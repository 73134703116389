import React from 'react'
import "./FooterLanding.scss"
// import { Link } from "react-router-dom"
import { Image } from 'react-bootstrap'
import goverment from "../../img/landing/goverment.svg"
import healthcare from "../../img/landing/healthcare.svg"
import retail from "../../img/landing/retail.svg"
import linkedin from "../../img/landing/linkedin.svg"
import youtube from "../../img/landing/youtube.svg"
import instagram from "../../img/landing/instagram.svg"
import logoblack from "../../img/landing/logo-black.svg"

import logo from "../../img/landing/logo.svg"

export class FooterLanding extends React.Component {

    contentFooterTop = () => {
        return (
            <div className="landingpage-footer-content">
                <div className="footer-content-teks">
                    <Image src={goverment} />
                    <h3>Goverment</h3>
                    <p>
                        Tingkatkan pelayanan sector pemerintahan dengan menggunakan kecerdasan buatan Indonesia
                    </p>
                </div>
                <div className="footer-content-teks">
                    <Image src={healthcare} />
                    <h3>Healtcare</h3>
                    <p>
                        Tingkatkan pelayanan sector pemerintahan dengan menggunakan kecerdasan buatan Indonesia
                    </p>
                </div>
                <div className="footer-content-teks">
                    <Image src={retail} />
                    <h3>Retail</h3>
                    <p>
                        Tingkatkan pelayanan sector pemerintahan dengan menggunakan kecerdasan buatan Indonesia
                    </p>
                </div>
            </div>
        )
    }

    contentFooterLink = () => {
        return (
            <div className="container-fluid text-white pt-3 rounded-lg" style={{ backgroundColor: "#2789FF" }}>
                <div className="container">
                    <div className="row">
                        {/* Left Column */}
                        <div className="col-md-6 mb-4">
                            <div className="d-flex align-items-center mb-3 ">
                                <a href={window._link_.about}>
                                    <Image src={logo} width="150" className="mr-2 bg-light p-2 rounded-lg" alt="Dikte.in logo" />
                                </a>
                            </div>
                            <p className="mb-4">
                                Membantu meringankan dan menyelesaikan masalah anda dengan bantuan teknologi AI
                            </p>
                            <div className="d-flex">
                                <a href="link_to_instagram" target="_blank" rel="noopener noreferrer" className="mr-3">
                                    <Image src={instagram} width="30" height="30" alt="Instagram" />
                                </a>
                                <a href="link_to_facebook" target="_blank" rel="noopener noreferrer" className="mr-3">
                                    <Image src={linkedin} width="30" height="30" alt="Facebook" />
                                </a>
                                <a href="link_to_linkedin" target="_blank" rel="noopener noreferrer">
                                    <Image src={youtube} width="30" height="30" alt="LinkedIn" />
                                </a>
                            </div>

                        </div>

                        {/* Right Column */}
                        <div className="col-md-6 mb-4">
                            <h5 className="font-weight-bold">Kontak Kami</h5>
                            <p>Hubungi tim kami untuk membantu meringankan dan menyelesaikan masalah anda dengan bantuan teknologi AI</p>
                            <a href={window._link_.contact} className="btn btn-light font-weight-bold text-primary px-4 py-2">
                                Kontak Kami
                            </a>
                        </div>
                    </div>
                    <p className="pb-3">
                        Jl. H. Naman No. 2A-C, Pondok Kopi Duren Sawit, Jakarta 13460 (+62) 21 2284 6729
                    </p>
                </div>
            </div>
        );
    };

    contentFooterSosmed = () => {
        return (
            <div className="landingpage-footer-sosmed pt-4 ">
                <p><Image src={logoblack} alt="" /> PT Bahasa Kinerja Utama</p>
            </div>
        )
    }

    render() {
        return (
            <div id="landingpage-footer">
                {/* <h1>Industry Solutions</h1> */}
                {/* {this.contentFooterTop()} */}
                {this.contentFooterLink()}
                {this.contentFooterSosmed()}
            </div>
        )
    }
}

export default FooterLanding
