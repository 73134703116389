import React from 'react';
import './CreditSchemaTable.scss';

const schemaTable = [
    { feature: "Unggah Transkrip", minUsage: "1 menit", creditCut: 1660 },
    { feature: "Unggah Translasi", minUsage: "1 menit", creditCut: 4630 },
    { feature: "Live Transkrip", minUsage: "1 menit", creditCut: 8330 },
    { feature: "Diarisasi", minUsage: "1 menit", creditCut: 300 },
    { feature: "Identifikasi", minUsage: "1 menit", creditCut: 140 },
    { feature: "VoicePrint", minUsage: "1 Pembicara", creditCut: 5830 },
    { feature: "Code-Mixing", minUsage: "1 menit", creditCut: 4760 },
    { feature: "Summary", minUsage: "1 menit", creditCut: 1110 },
];

const CreditSchemaTable = () => (
    <div className="table-container">
        <table className="schema-table">
            <thead>
                <tr>
                    <th>Fitur</th>
                    <th>Minimal Pemakaian</th>
                    <th>Potongan Kredit</th>
                </tr>
            </thead>
            <tbody>
                {schemaTable.map((row, index) => (
                    <tr key={index}>
                        <td>{row.feature}</td>
                        <td>{row.minUsage}</td>
                        <td>{new Intl.NumberFormat('id-ID').format(row.creditCut)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const IllustrationCard = () => (
    <div className="illustration-card">
        <h2 className="title">Contoh</h2>
        <p className="description">
            
Jika Anda mengunggah file audio untuk ditranskripsi dengan tambahan fitur diarisasi dan identifikasi, 
dan durasi audionya <b>1 jam</b>, jumlah kredit yang akan digunakan adalah:
        </p>
        <div className="formula">
            <pre>
                TPK = DD * (UT + DR + ID) <br />
                = 1 * 3600 / 60 * (1.660 + 300 + 140) <br />
                = 60 * 2.100 <br />
                = 126.000 Kredit
            </pre>
        </div>
        <div className="notes">
            <b>Keterangan:</b>
            <ul>
                <li>TPK = Total Potongan Kredit</li>
                <li>DD = Detik Durasi / 60</li>
                <li>UT = Unggah Transkrip (1.660 kredit)</li>
                <li>DR = Diarisasi (300 kredit)</li>
                <li>ID = Identifikasi (140 kredit)</li>
            </ul>
        </div>
    </div>
);

const CreditSchema = () => (
    <div className="credit-schema-container">
        <h2 className="main-title">Skema Pemotongan Kredit</h2>
        <div className="content-grid">
            <CreditSchemaTable />
            <IllustrationCard />
        </div>
    </div>
);

export default CreditSchema;
