import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web';
import { Button, Navbar, Image, Nav } from 'react-bootstrap'

import './Navbar.scss'

import homelogo from '../../img/landing/homelogo.svg'


function NavbarFeature (props) {
    const { id_token } = useSelector(state => state.UserManagementReducers);

	const { keycloak } = useKeycloak();

    return (
        <div id="navbar-feature">
            <Navbar collapseOnSelect expand="md" variant="dark" id="navbar-wrapper">
                <Navbar.Brand href="/">
                    <Image src={homelogo} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    {/* <Nav className="mr-auto"></Nav> */}
                    <Nav className="ml-auto">
                        {/* <NavLink
                            to="/tts"
                            className="navbar-feature-nav"
                            activeClassName="active">
                            Text to Speech
                        </NavLink>
                        <NavLink
                            to="/stt"
                            className="navbar-feature-nav"
                            activeClassName="active">
                            Speech to Text
                        </NavLink> */}
                        {/* <NavLink
                            to="/diktein"
                            className="navbar-feature-nav"
                            activeClassName="active">
                            Diktein
                        </NavLink> */}
                        <a href={window._link_.contact}>
                            <Button className="btn-navbar-kontak-kami blue" id="landing_navbar_contact">
                                Kontak Kami
                            </Button>
                        </a>
                        {/* { id_token === null && 
                            // <a href={window._link_.diktein+"/login"}>
                            //     <Button className="btn-navbar-kontak-kami sub" id="landing_navbar_login">
                            //         Login
                            //     </Button>
                            // </a>
                            <NavLink to="/login">
                                <Button className="btn-navbar-kontak-kami sub" id="landing_navbar_login">
                                    Login
                                </Button>
                            </NavLink>
                        } */}
                        { !keycloak.authenticated && 
                            <NavLink to="/login">
                                <Button className="btn-navbar-kontak-kami sub" id="landing_navbar_login">
                                    Login
                                </Button>
                            </NavLink>
                        }
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
        // <div id="navbar-feature">
        //     <Link to="/landingpage">
        //         <Image src={homelogo} />
        //     </Link>
        //     <Nav>
        //         <NavLink
        //             to="/tts"
        //             className="navbar-feature-nav"
        //             activeClassName="active">
        //             Text to Speech
        //         </NavLink>
        //         <NavLink
        //             to="/stt"
        //             className="navbar-feature-nav"
        //             activeClassName="active">
        //             Speech to Text
        //         </NavLink>
        //     </Nav>
        //     <Button className="btn-navbar-kontak-kami">
        //         Kontak Kami
        //     </Button>
        // </div>
    )
}

export default NavbarFeature
