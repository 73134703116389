export var tutorial_list = {
    status: "success",
    error: "",
    services: [{
        id_service:1,
        name:"Tutorial Integrasi API Text to Speech",
        description:"",
        file_url:"/tutorial-tts.pdf"
    }, {
        id_service:2,
        name:"Tutorial Integrasi API Speech to Text",
        description:"",
        file_url:"/tutorial-stt.pdf"
    }, {
        id_service:3,
        name:"Tutorial Integrasi API NLP",
        description:"",
        file_url:"/tutorial-nlp.pdf"
    }]
}


// DUMMY DATA USER PACKAGE

export const userpackage_list_dummy = [
    {
          id_user_package: 1,
          name:"Text to Speech 1 Engine",
          description: "dapatkan pelayanan tambahan berupa unlimited character dan durasi pelayanan  bulanan",
          name_plan: "Time Based",
          status:"Active",
        
    },
    {
        id_user_package : 2,
        name : "Text to Speech 250 Engine",
        description: "dapatkan pelayanan tambahan berupa unlimited character dan durasi pelayanan  bulanan",
        name_plan: "Sewa Tahunan",
        status:"Pending",
    },
]

export const userpackage_detail_dummy = {
    status: "",
    error: "",
    volume_base : {
        name:"Kuota Karakter",
        kuota:"Unlimited",
    },
    time_base : {
        name:"Sisa Hari",
        kuota:"312",
    },
    error_count: 18,
    api_keys:"3z089idk",
    tutorial_file_url: "Documentation.pdf"
}


export const data_usage_history_dummy = {
    status: "",
    error: "",
    history:[
        {
            id: 1,
            date:"May 01, 2020",
            quantity:"",
        },
        {
            id: 2,
            date:"Jan 01, 2020",
            quantity:"",
        },
        {
            id: 3,
            date:"March 01, 2020",
            quantity:"",
        },
    ],
    unit:"100",
    offset: 0,
    limit: 0,
    total: ""
}

// DUMMY DATA SERVICE

export const service_list_dummy = [
    {
        id_service : 1,
        name : "Text to Speech 1 Engine",
        description: "Lorem ipsum dolor sit amet constrector ipsum.",
        planType : "Pro Plan"
    },
    {
        id_service : 2,
        name : "Text to Speech 250 Engine",
        description: "Lorem ipsum dolor sit amet constrector ipsum.",
        planType : "Enterprise plan"
    },
]


export const service_packages_dummy = [
    {
        id_package: 1,
        name:"Volume Based",
        tittle_description:"Baik untuk proyek kecil dan besar",
        price:"100000",
        discount:"",
        type:"",
        description:[
            "1 Engine",
            "Server di Cloud",
            "1 juta Character",
            "Dukungan Penuh", 
        ],
        plan_duration:[
            {
                id_plan_duration:"1",
                name:"month",
                price:100000,
            },
            {
                id_plan_duration:"2",
                name:"month",
                price:200000,
            },
        ]
    },
    
    {
        id_package: 2,
        name:"Time Based",
        tittle_description:"Baik untuk proyek kecil dan besar",
        price:"3500000",
        discount:"",
        type:"Bulanan",
        description:[
            "1 Engine",
            "Server di Cloud",
            "Unlimited Character",
            "Dukugan Penuh", 
        ],
        plan_duration:[{
           id_plan_duration:"",
           name:"",
           price:"",
        }]
    },
    
    {
        id_package: 3,
        name:"Sewa",
        tittle_description:"",
        price:"1000000000",
        discount:"",
        type:"Tahunan",
        description:[
            "250 Engine",
            "Include Hardware",
            "Unlimited Character",
            "Dukungan Penuh", 
        ],
        plan_duration:[{
           id_plan_duration:"",
           name:"",
           price:"",
        }]
    }
]