import React, { useState, useEffect } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { useSetState } from 'react-use';
import store from "../../../../store";
import { useSelector } from 'react-redux';

// FUNCTIONS
import { fetchUsageHistory } from "../../../../reducers/UserPackageReducers";
import { toastNotification } from '../../../../modules/ToastNotification';

// COMPONENTS
import NcloudPagination from "../../../../components/NcloudPagination";
import Loading from "../../../../components/Loading";
import NoData from '../../../../components/NoData';

function UsageHistory(props) {
    const initialState = {
        isLoading: false,
        historyList: [],
        activePage: 1,
        offset: 0,
        limit: 10,
        total: 0,
        unit: "",
    };

    const {
        id_user_package = null,
        updateUnit = () => { },
    } = props;

    const { id_token } = useSelector(state => state.UserManagementReducers);

    const [state, setState] = useSetState({ ...initialState });
    const [selectedType, setSelectedType] = useState('All');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const itemsPerPageOptions = [10, 20, 50, 100];

    useEffect(() => {
        if (id_user_package === null) return;

        getUsageHistoryData();
    }, [id_user_package, state.offset, state.limit]);

    const getUsageHistoryData = () => {
        let { offset, limit } = state;

        setState({ isLoading: true });

        // fetch usage history
        store.dispatch(fetchUsageHistory(id_token, id_user_package, offset, limit))
            .then((res) => {
                setState({
                    isLoading: false,
                    total: res.total,
                    historyList: res.history,
                    unit: res.unit,
                });
                updateUnit(res.unit);
            })
            .catch((err) => {
                setState({ isLoading: false });
                toastNotification("error", "Gagal memuat data penggunaan", err.toString());
            });
    };

    const handlePageChange = (pageNumber) => {
        setState({
            activePage: pageNumber,
            offset: (pageNumber - 1) * state.limit,
        });
    };

    const handleItemsPerPageChange = (newLimit) => {
        setState({
            limit: newLimit,
            activePage: 1,
            offset: 0,
        });
    };

    // Filter data berdasarkan jenis pemakaian dan rentang waktu yang dipilih
    const filteredHistoryList = state.historyList.filter((usage_history) => {
        const usageDate = new Date(usage_history.date);
        const isTypeMatch = selectedType === 'All' || usage_history.type === selectedType;
        const isDateInRange = (!startDate || usageDate >= new Date(startDate)) &&
            (!endDate || usageDate <= new Date(endDate));
        return isTypeMatch && isDateInRange;
    });

    const totalPages = Math.ceil(state.total / state.limit);
    // Fungsi untuk mereset filter tanggal
    const resetDateFilter = () => {
        setStartDate('');
        setEndDate('');
    };

    // State untuk sorting, ubah ke 'desc' jika ingin urutan awal dari besar ke kecil
    const [sortOrder, setSortOrder] = useState('desc'); // 'asc' untuk ascending, 'desc' untuk descending

    // Fungsi untuk mengatur sorting tanggal
    const handleSortDate = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
    };

    // Sorting berdasarkan tanggal
    const sortedHistoryList = [...filteredHistoryList].sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    // console.log(`sortedHistoryList`, sortedHistoryList);
    

    return (
        <div className="usage-history-container">
            <h3 className='' style={{ color: "#3372CA" }}>Riwayat Penggunaan</h3>
            <div className="mb-3 d-flex justify-content-between align-items-end">
                <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        Filter Jenis Pemakaian: {selectedType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {['All', ...new Set(state.historyList.map((item) => item.type))].map((type, index) => (
                            <Dropdown.Item key={index} onClick={() => setSelectedType(type)}>
                                {type}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>

                <div className="d-flex align-items-end">
                    <div className="mr-2">
                        <label htmlFor="startDate">Dari Tanggal:</label>
                        <input
                            type="datetime-local"
                            id="startDate"
                            className="form-control"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="mr-2">
                        <label htmlFor="endDate">Sampai Tanggal:</label>
                        <input
                            type="datetime-local"
                            id="endDate"
                            className="form-control"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <Button variant="secondary" onClick={resetDateFilter}>
                        Reset Tanggal
                    </Button>
                </div>
            </div>



            <div className="">

                <div className="table-responsive" style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                    <table className="table table-bordered ">
                        <thead className="thead-dark" style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                            <tr>
                                <th>No</th>
                                <th onClick={handleSortDate} style={{ cursor: 'pointer' }}>
                                    Tanggal {sortOrder === 'asc' ? '▲' : '▼'}
                                </th>
                                <th>Nama File</th>
                                <th>Pemakaian Kredit</th>
                                <th>Jenis Pemakaian</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.isLoading ? (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        <Loading />
                                    </td>
                                </tr>
                            ) : (
                                sortedHistoryList.length === 0 ? (
                                    <tr>
                                        <td colSpan="4" className="text-center">
                                            <NoData />
                                        </td>
                                    </tr>
                                ) : (
                                    sortedHistoryList.map((usage_history, index) => (
                                        <tr key={"usage-history-" + usage_history.id}>
                                            <td>{(state.activePage - 1) * state.limit + index + 1}</td>
                                            <td>{usage_history.date}</td>
                                            <td>{usage_history.filename}</td>
                                            <td>{usage_history.quantity}</td>
                                            <td>{usage_history.type}</td>
                                        </tr>
                                    ))
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="usage-history-bottom">
                <NcloudPagination
                    activePage={state.activePage}
                    itemPerPage={state.limit}
                    totalItems={state.total}
                    onChange={handlePageChange}
                />

            </div>
            <div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <Dropdown>
                        <Dropdown.Toggle variant="info" id="items-per-page-dropdown">
                            Tampilkan {state.limit}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {itemsPerPageOptions.map((option, index) => (
                                <Dropdown.Item key={index} onClick={() => handleItemsPerPageChange(option)}>
                                    {option}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <div className="mt-2">
                        Halaman {state.activePage} dari {totalPages}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default UsageHistory;
