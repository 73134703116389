import React from 'react';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import BaseWalkthrough, { basic_option, locale } from './BaseWalkthrough';


const StepContent = styled.div`
	font-size: 20px;
`;

function WalkthroughServices(props) {
	const { callback=()=>{} } = props;
	
	const userDetail = useSelector(state => state.UserManagementReducers.userDetail)

	const [dropdownSelectServiceTitle, setDropdownSelectServiceTitle] = useState("Select service")
	const [selectedService, setSelectedService] = useState(null)
	const [username, setUsername] = useState("")

	const services = [
		{
			id: "diktein",
			name: "Dikte.in",
			steps: [{
				content: (
					<StepContent>
						Click here to see detailed information about Dikte.in
					</StepContent>
				),
				...basic_option,
				target: ".services-card#diktein",
			}]
		},{
			id: "stt",
			name: "Speech to Text",
			steps: [{
				content: (
					<StepContent>
						Click here to see detailed information about Speech to Text
					</StepContent>
				),
				...basic_option,
				target: ".services-card#stt"
			}]
		},{
			id: "tts",
			name: "Text to Speech",
			steps: [{
				content: (
					<StepContent>
						Click here to see detailed information about Text to Speech
					</StepContent>
				),
				...basic_option,
				target: ".services-card#tts"
			}]
		},
	];

    const first_steps = [
        {
			content: (
				<StepContent>
					Hi {username}, I'm Tella <br />
					Welcome to console bahasakita <br />
					Do you want to see the tutorials to use this application?
				</StepContent>
			),
			...basic_option,
			placement: 'center',
			target: 'body',
        }, 
        {
			event: "select-service",
			content: (
				<StepContent>
					Choose tutorial you need 
					<br />
					<br />
					<Dropdown>
						<Dropdown.Toggle>{dropdownSelectServiceTitle}</Dropdown.Toggle>
						<Dropdown.Menu>
							{ services.map(({id, name}) => {
								return (
									<Dropdown.Item 
										key={id}
										active={id===selectedService} 
										onClick={() => setSelectedService(id)}
									>{name}
									</Dropdown.Item>
								)
							})}
						</Dropdown.Menu>
					</Dropdown>
				</StepContent>
			),
			disableBeacon: true,
			...basic_option,
			locale: {
				...locale,
				last: (selectedService === null ? "Nope" : "Next")
			},
			placement: 'center',
			target: 'body',
        }
    ]
	
	useEffect(() => {
		if ( userDetail === null )
			return;

		// get firstname from userDetail data
		const firstname = userDetail.firstname !== "" ? userDetail.firstname : userDetail.id_user;
		setUsername(firstname);
	}, [userDetail])

	useEffect(() => {		
		// console.log(selectedService)	
		const index = services.findIndex(({id}) => id === selectedService);
		if ( index > -1 ) {
			setDropdownSelectServiceTitle(services[index].name);
		}
	}, [selectedService])	

    const _callback = (data) => {
		callback(data);
    }

	let steps = [];
	if ( selectedService === null ) {
		steps = first_steps;
	} else {
		const index = services.findIndex(({id}) => id === selectedService);
		if ( index > -1 ) {
			steps = [...first_steps, ...services[index].steps];
		}
	}

	// console.log(steps)

	return (
        <>
			<BaseWalkthrough steps={steps} callback={_callback} />
        </>
    )
}

export default WalkthroughServices;