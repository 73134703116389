import React from 'react';
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { toastNotification } from "../../modules/ToastNotification";
import { fetchResetPassword } from "../../reducers/UserManagementReducers";

import { Modal, Spinner } from "react-bootstrap";
import ModalVerifyEmailSent from "./ModalVerifyEmailSent";

import './Account.scss'


function ModalInfoCreatePassword(props) {
    const { 
        closeButton=false,
        show=false, 
        onHide=()=>{}, 
    } = props;

    const dispatch = useDispatch();

	const { userDetail } = useSelector(state => state.UserManagementReducers);

    const [loading, setLoading] = useState(false);
    const [showModalVerifyEmailSent, setShowModalVerifyEmailSent] = useState(false);

    const handleCreatePasswordClick = (e) => {
        e.preventDefault();

        setLoading(true);
        
        dispatch(fetchResetPassword(userDetail.email))
            .then((res) => {
                setLoading(false);

                onHide();
                setShowModalVerifyEmailSent(true);
            })
            .catch((err) => {
                setLoading(false);

                toastNotification("error", "Failed to send email", err.toString())
            })
    }

    return (
        <>
            <ModalVerifyEmailSent 
                show={showModalVerifyEmailSent}
                onHide={() => setShowModalVerifyEmailSent(false)}
                email={userDetail.email}
            />

            <Modal 
                className="modal-verify-email" 
                show={show} 
                onHide={onHide}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton={closeButton}>
                    {/* <div className="title">Enter your email to proceed</div> */}
                </Modal.Header>
                <Modal.Body>
                    {/* <div className="title">Verify Email</div> */}
                    <div className="content">
                        <div>You haven't create password</div>
                        <div>Send link to {userDetail.email} to create password?</div>
                        <div className="button-group">
                            <button type="submit" 
                                className="btn btn-primary" 
                                id="submit"
                                onClick={handleCreatePasswordClick}
                                disabled={loading}
                            >
                                { loading ?      
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    : "Send"
                                }
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalInfoCreatePassword;