import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

//CSS IMPORTS
import "bootstrap/dist/css/bootstrap.min.css";
import './App.scss';
import 'react-notifications/lib/notifications.css';

//FUNCTIONS
import { fetchRefreshToken, updateIdToken } from './reducers/UserManagementReducers';
import { updateContinueToCheckoutFlag } from './reducers/BillingReducers';

//COMPONENTS
import {NotificationContainer} from 'react-notifications';
import LoadingFullPage from './components/LoadingFullPage';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';


function App() {
	// const location = useLocation();
	const dispatch = useDispatch();
	const { keycloak, initialized } = useKeycloak();

    // useEffect(() => {
    //     const refreshToken = async () => {
    //         try {
    //             await dispatch(fetchRefreshToken());
    //         } catch (error) {
    //             console.error("Failed to refresh token:", error);
    //         }
    //     };

    //     refreshToken();
    // }, [ dispatch]);
	
	useEffect(() => {
		const snapSrcUrl = `${process.env.REACT_APP_MIDTRANS_URL}` // 'https://app.sandbox.midtrans.com/snap/snap.js';
		// console.log(process.env.REACT_APP_MIDTRANS_URL)
		// console.log(process.env.REACT_APP_PASSWORD)
		const myMidtransClientKey = `${process.env.REACT_APP_MIDTRANS_CLIENT_KEY}` // 'SB-Mid-client-qWuvpaWxNF3nBq6B', change this according to your client-key	  
		const script = document.createElement('script');
		script.src = snapSrcUrl;
		script.setAttribute('data-client-key', myMidtransClientKey);
		script.async = true;
	  
		document.body.appendChild(script);
	  
		return () => {
		  document.body.removeChild(script);
		}
	  }, []);

    useEffect(() => {
		dispatch(updateIdToken(keycloak.token));
    }, [keycloak.token])

    // useEffect(() => { 
	// 	// clear payment flag if user move from these paths
	// 	const account_path = [
	// 		"/login",
	// 		"/register",
	// 		"/reset-password",
	// 	]

	// 	if ( account_path.indexOf(location.pathname) === -1 ) {
	// 		dispatch(updateContinueToCheckoutFlag(false));
	// 	}
    // }, [location.pathname])

	// console.log(keycloak.authenticated)
	// console.log(id_token)

	// show loading page if keycloak has not initialized
	if ( !initialized ) { 
		return <LoadingFullPage info="Loading app" />;
	}

	return (
		<div className="App">
			<NotificationContainer/>
			<div id="popup-notification-container"></div>
			<section>
				{ !keycloak.authenticated ? // id_token === null ?
					<PublicRoutes />
					:
					<PrivateRoutes />
				}
			</section>
		</div>
	)
}

export default App;
